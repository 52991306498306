:focus {
  border-color: $focus-outline-color;
  box-shadow: 0 0 0 2px $focus-outline-color;
  outline: none;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  box-shadow: none;
}

.focus--mouse {
  border-color: inherit;
  box-shadow: none;
  outline: none;
}
