.cookiebar {
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  position: fixed;
  width: 100%;
  z-index: $zindex-cookiebar;
  background-color: $neutral-1-a8;
  padding: $v-gap * 3 $v-gap * 4;

  &.cookiebar-top {
    bottom: auto;
    top: 0;
  }

  &.show {
    display: block;
  }

  p {
    font-size: 1rem;
    line-height: 1.25em;
    color: $white;
    margin-bottom: 0;
    br {
      display: none;
    }
  }

  .cookiebar-buttons {
    text-align: right;
    margin-top: $v-gap * 4;
    padding: 0;
    line-height: 1.25em;
  }

  .cookiebar-btn {
    background: transparent;
    border: none;
    padding: 0;
    color: $white;
    letter-spacing: 0.1em;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.875rem;
    display: inline-block;
    line-height: 1em;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
    &:last-child {
      margin-left: $v-gap * 4;
    }
    &:not(.cookiebar-confirm) {
      color: $neutral-2-a1;
    }
  }
}

//small - tablet
@media (min-width: #{map-get($grid-breakpoints, sm)}) {
  .cookiebar {
    p {
      font-size: 0.889rem;
    }
    .cookiebar-btn {
      font-size: 0.778rem;
    }
  }
}

//Tablet vertical
@media (min-width: #{map-get($grid-breakpoints, md)}) {
  .cookiebar {
    padding: $v-gap * 4 $v-gap * 6;
    &.show {
      display: flex;
      justify-content: space-between;
    }
    p {
      width: 60%;
      margin-right: $v-gap * 3;
      br {
        display: block;
      }
    }

    .cookiebar-buttons {
      margin-top: 0;
      width: 40%;
    }
  }
}

//Tablet horizontal / small desktop
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
  .cookiebar {
    max-width: $v-gap * 104;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    border-radius: $border-radius $border-radius 0 0;
    p {
      width: 66%;
    }
    .cookiebar-buttons {
      width: 33%;
    }
  }
}
